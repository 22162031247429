@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

:root{
  --logo-size: 64px;
  --My-black: #222222;
  --My-gray: #5A5A5A;
  --Section-color: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 249, 246, 0.20) 100%);
  --Overflow: linear-gradient(180deg, rgba(224, 225, 241, 0.00) 87.12%, #E0E1F1 95.78%), linear-gradient(180deg, #E0E1F1 1.4%, rgba(224, 225, 241, 0.00) 8.94%);
  --Glass-stroke: #FFFCF9;
  --Glass-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 249, 246, 0.20) 100%);
  --BluePurble-gradient: linear-gradient(91deg, #6B29F8 0%, #9A1FFB 100%);
  --BluePurble-Glow: 0px 0px 8px 0px #8325FA, 0px 0px 2px 0px #FFF inset;
  --BluePurble-Glow-edge: 1px solid #B47AFF;
  --New-BluePurble-Gradient: linear-gradient(91deg, #0570EA 0%, #9620FB 100%);
  --New-BluePurble-Gradient-Hover: linear-gradient(91deg, #0459B9 0%, #6503B9 100%);
  --New-BluePurble-Glow: 0px 0px 8px 0px #4B4BF3, 0px 0px 2px 0px #FFF inset;
  --New-BluePurble-Glow-edge: 1px solid #999AFF;
  --Common-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.25);
  --Dissabled-gray: rgba(128, 128, 128, 0.10);
  --White-light-BG: rgba(255, 255, 255, 0.50);
  --White-light-cast: 0px 0px 8px 0 white;
  --Purbleish-blue-uniform: #3B31C8;
  --Destructive-red: #C2290A;
  --Attention-modal-background: #F1F3FD;
  --Attention-modal-overlay-background: rgba(0, 0, 0, 0.25);
}


.App {
  display: flex;
  height: 100vh;
  color: var(--My-black);
  /* background-image: url('images/Background_v3.png'); */

  h2{
    font-family: "Lora", serif;
    font-size: 20px;
    font-weight: 600;
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: auto;
    padding: 0 12px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    border: 1px solid var(--My-black);
    background: transparent;

    img{
      width: 24px;
    }
  }

  button:hover{
    cursor: pointer;
    border: 1.5px solid var(--My-default-black, #222);
    background: rgba(255, 255, 255, 0.40);
  }

  button:active{
    cursor: pointer;
  }

  .X-button{
    background-color: transparent;
    border: transparent;
    transition: background 0.3s ease;
    transition: border 0.3s ease;
    transition: box-shadow 0.3s ease;
    width: 40px;
    border-radius: 100%;
    padding: 0;
  }

  .X-button:hover{
    background: var(--White-light-BG);
    border: solid 1px white;
    box-shadow: var(--White-light-cast);
  }

  .Start-conversation-button{
    background: var(--New-BluePurble-Gradient);
    border: var(--New-BluePurble-Glow-edge);
    color: white;
    display: flex;
    box-shadow: var(--New-BluePurble-Glow);
    transition: box-shadow 0.3s ease;

    img{
      margin-bottom: 1.5px;
    }
  }

  .Start-conversation-button:hover {
    background: var(--New-BluePurble-Gradient-Hover);
    box-shadow: 0px 0px 4px 0px #4B4BF3;
    border: var(--New-BluePurble-Glow-edge);
  }

  .Start-conversation-button:active {
    box-shadow: 0px 0px 12px 0px #4B4BF3, 0px 0px 8px 0px #FFF inset;
  }

  .Start-conversation-button:focus{
    border: #0459B9;
  }

  .Dot-spacer{
    display: block;
    width: 2px;
    height: 2px;
    background-color: var(--My-gray);
    border-radius: 100%;
    margin: auto 0;
  }

  /* only added this form styling because for some reason this styling is not added to the Input-container class when I command it further down. */
  form{
    display: flex;
    align-items: center;

    .Button-wrapper {
      display: flex;
      align-self: stretch;
      align-items: flex-end;
    }
  }
}

@media (max-width: 743px) {
  .App{
    flex-direction: column;
  }
}
/* ##################################################### */
/* ###### This is the styling of the Landing Page ###### */
/* ##################################################### */

.Landing-page{
  position: absolute;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;

  .Background-overlay{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: radial-gradient(50% 50% at 50% 50%, rgba(224, 225, 241, 0.20) 0%, rgba(224, 225, 241, 0.90) 100%);
    padding-top: 13.5vh;

    .Sphere{
      width: 66vh;
      height: 66vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--Glass-stroke);
      box-shadow: 0px 0px 240px -75px rgba(248, 243, 255, 0.80), 0px -20px 80px 40px rgba(248, 243, 255, 0.20) inset, 0px 20px 40px 40px rgba(248, 243, 255, 0.25) inset;
      border-radius: 100%;
      overflow: hidden;
      transition: all 5s ease-in-out;

      .Sphere-content{
        position: absolute;
        z-index: 1;
        width: 66vh;
        height: 66vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Lora", serif;
        color: var(--My-black);
        background: rgba(0, 0, 0, 0.08);
        border-radius: 100%;
        box-shadow: 0px -20px 80px 40px rgba(248, 243, 255, 0.20) inset, 0px 20px 40px 40px rgba(248, 243, 255, 0.25) inset;

        p{
          margin: 0;
          font-size: calc(66vh * 0.023);
        }
        div{
          display: flex;
          align-items: center;
          margin: 0 0 12px 0;

          img {
            width: calc(66vh * 0.115);
            height: calc(66vh * 0.115);
            margin-right: 8px;
          }
          h1{
          margin: 0;
          font-weight: 400;
          font-size: calc(66vh * 0.1);
          }
          .Beta-text{
            margin: calc(66vh * 0.018) 0 0 8px;
          }
        }
        h2{
          margin: 0 0 54px 0;
          font-weight: 400;
          font-size: calc(66vh * 0.03);
        }

        /* @media (max-width: 540px) {
          h2{
            display: flex;
            font-size: 18px;
            width: calc(100vw -32px);
          }
        } */

        form{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          border: 1px solid white;
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.50);
          box-shadow: 0px 0px 40px -2px #FFF;
          backdrop-filter: blur(20px);
          width: 75%;

          input{
            background-color: transparent;
            border: 0;
            width: 80%;
            height: 40px;
            font-family: inherit;
            margin-left: 5.5px;
            font-size: 1em;
            border-radius: 12px;
          }

          button{
            margin: 6px 6px 6.5px 0;
            background: var(--New-BluePurble-Gradient);
            border: var(--New-BluePurble-Glow-edge);
            color: white;
            width: 20%;
            gap: 4px;
            box-shadow: var(--New-BluePurble-Glow);
            transition: box-shadow 0.3s ease;
          }

          button:hover {
            background: var(--New-BluePurble-Gradient-Hover);
            box-shadow: 0px 0px 4px 0px #4B4BF3;
          }

          button:active {
            box-shadow: 0px 0px 12px 0px #4B4BF3, 0px 0px 8px 0px #FFF inset;
            cursor: pointer;
          }

          button:focus{
            border: #0459B9;
          }
        }

        .Error-message{
          color: #CC0000;
          font-weight: 600;
          height: 16px;
          font-size: 16px;
          text-shadow: 0px 0px 16px rgba(244, 71, 71, 0.50);
        }

        a{
          color: #3826F2;
          font-weight: 460;
          text-shadow: 0px 0px 16px rgba(41, 21, 255, 0.50);
          margin: 8px 0 0 0 
        }

        a:visited{
          color: #5647F4;
        }

      }
    }
  }

  .Shadow{
    position: absolute;
    width: 70vh;
    height: 20vh;
    border-radius: 100%;
    overflow: hidden;
    left: 0; 
    right: 0;
    top: 0;
    bottom: -10vh;
    margin: auto auto 0 auto;
    filter: blur(34.400001525878906px);

    .Darkness{
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 0 100vw rgba(0, 0, 0, 0.30) inset;
      z-index: 1;
    }
  }

  .Thoger-Signature{
    position: absolute;
    z-index: 100;
    width: var(--logo-size);
    bottom: 0;
    right: 0;
    margin: 16px;
  }
}

@media (max-width: 800px){
  .Landing-page .Background-overlay{
    padding-top: 16vh;
  
    .Sphere{
      width: 95vw;
      height: 95vw;

      .Sphere-content{
        width: 95vw;
        height: 95vw;
      }
    }
  }
}

@media (max-width: 743px){
  .Landing-page .Background-overlay{
    padding-top: 21vh;

    .Sphere .Sphere-content{
      p{
        font-size: 11px;
      }

      div{
        margin-bottom: 4px;
        img {
          width: calc(50vh * 0.115);
          height: calc(50vh * 0.115);
        }

        h1{
        font-size: calc(50vh * 0.1);
        }
      }

      h2{
        margin: 0 48px 24px 48px;
        font-size: 16px;
      }

      form {
        gap: 8px;
        width: 78%;

        input{
          font-size: 12px;
          height: 32px;
        }
        button{
          font-size: 12px;
          height: 32px;
        }
      }

      .Error-message{
        font-size: 12px;
      }

      a{
        font-size: 12px;
      }
    }
  }

  .Landing-page .Shadow{
    filter: blur(50px);
  }
}

@media (max-width: 389px){
  .Landing-page .Background-overlay .Sphere .Sphere-content form {
    border-radius: 13px;
    width: 84%;

    input{
      height: 30px;
    }
    button{
      height: 30px;
      margin: 0;
    }
  }
}

.Background-animated-light{
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.Animated-light{
  width: 100%;
}

.Sphere-content.fade-ladningpage-content-out{
  transition: opacity 1.5s ease-out;
  opacity: 0;
}

.Shadow.fade-ladningpage-content-out{
  transition: opacity 1.5s ease-out;
  opacity: 0;
}

.Thoger-Signature.fade-ladningpage-content-out{
  transition: opacity 1.5s ease-out;
  opacity: 0;
}

.Sphere.enlarge{
  transition: all 3s ease-out;
  transform: scale(100);
}

.Landing-page.fade-app-in {
  transition: opacity 3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

/* ################################################ */
/* ###### This is the styling of the sidebar ###### */
/* ################################################ */
.Side-bar {
  display: flex;
  padding-bottom: 1em;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-right: 1px solid var(--Glass-stroke);
  background: var(--Section-color);

  .Modules{
    display: flex;
    flex-direction: column;
    gap: 32px;

    .App-logo {
      height: var(--logo-size);
      cursor: pointer;
      margin: 8px;
    }

    .Side-section-toggles{
      display: flex;
      flex-direction: column;
      gap: 4px;

      button{
        background-color: transparent;
        justify-content: flex-start;
        border: none;
        margin: 0;
        padding: 8px 0;
        width: 100%;
        align-items: center;
        font-weight: 400;
        height: 64px;
        font-size: 14px;
        border-radius: 0;

        .Side-selection-indicator{
          height: 64px;
          width: 4px;
          position: absolute;
          background-color: transparent;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: background-color 0.3s ease;
        }

        .Side-button-image-n-text{
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          align-items: center;
          gap: 8px;
          position: relative;

          img{
            width: 24px;
            height: 24px;
          }

          .Selected-philosophers-counter{
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: start;
            height: 16px;
            padding: 0px 5px;
            background: var(--New-BluePurble-Gradient);
            border-radius: 7.5px;
            margin-left: 20px;
            position: absolute;
            top: 13px;

            span{
              color: white;
              font-size: 13.5px;
              font-weight: 550;
            }
          }

          p{
            margin: 0;
            padding: 0;
            color: var(--My-gray);
          }
        }
      }
      
      .Side-bar-divider{
        width: auto;
        margin: 8px;
        height: 1px;
        background: var(--Glass-stroke);
      }

      button:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.2);

        .Side-selection-indicator{
          background-color: var(--My-black);
        }

        .Side-button-image-n-text{
          p{
            color: var(--My-black);
            font-weight: 500;
          }
        }
      }

      button:focus{
        border: #0459B9;
      }

      .Side-button.active p{
        background: var(--New-BluePurble-Gradient);
        font-weight: 500;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      
      .Side-button.active .Side-selection-indicator{
        background-color: #1E64EE;
      }
    }
  }
  .Thoger-Underskrift{
    width: var(--logo-size);
  }
}

@media (hover: none) {
  .Side-bar .Modules .Side-section-toggles button:hover {
    background-color: transparent;

    .Side-selection-indicator{
      background-color: transparent;
    }
  }
}

@media (max-width: 743px){
  .Side-bar{
    flex-direction: row;
    padding: 0;
    justify-content: flex-start;
    gap: 16px;
    border-bottom: 1px solid var(--Glass-stroke);
    border-right: none;


    .Modules{
      flex-direction: row;
      width: 100%;
      height: 100%;
      justify-content: space-between; 

      .Side-section-toggles{
        flex-direction: row;

        .Side-button{
          padding: 18px 0 0 0;
          width: 80px;

          .Side-selection-indicator {
            position: absolute;
            width: 80px;
            height: 4px;
            top: 76px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            border-bottom-right-radius: 0;
          }
        }

        .Side-bar-divider{
          width: 1px;
          margin: 8px 0;
          height: auto;
          background: var(--Glass-stroke);
        }
      }
    }

    .Thoger-Underskrift{
      display: none;
    }
  }
}

@media (max-width: 464px){
  #Settings-button-hidden{
    display: none
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 90s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (hover: none) {
  .Side-bar .Modules .Side-section-toggles button:hover {
    background-color: transparent;

    .Side-selection-indicator{
      background-color: transparent;
    }

    .Side-button-image-n-text{
      p{
        color: var(--My-gray);
      }
    }
  }
}

/* ##################################################### */
/* ###### This is the styling of the Divider Line ###### */
/* ##################################################### */

#Divider-line{
  display: flex;
  width: calc(100% - 80px);
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 40px;

  div{
    display: block;
    background-color: #FFF;
    box-shadow: 0px 0px 4px 1px #ffffff, 0px 0px 16px 0px #FFFFFF inset; ;
  }

  #End-sphere{
    width: 6px;
    height: 6px;
    border-radius: 100%;
    padding: 0;
  }

  #Spacer-line{
    width: 48%;
    height: 1px;
    padding: 0;
  }

  img{
    margin: 0 -8px;
  }
}

@media (max-width: 743px){
  .Philosopher-catalog{
    #Divider-line{
      width: calc(100% - 32px);
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}



/* ############################################################################ */
/* ###### This is the styling of the top part of the Philosopher Catalog ###### */
/* ############################################################################ */


.Philosopher-catalog{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  transition: opacity 1s cubic-bezier(0.5, 1, 0.5, 1);
  opacity: 1;
  position: absolute;
  z-index: 3;
  font-family: "Lora", serif;
  text-align: center;
  -webkit-text-stroke: 0.3px var(--Glass-stroke); 
  text-shadow: 0px 4px 12px rgba(0,0,0,0.25);
  overflow-y: scroll;
  gap: 40px;
  background-image: url('./images/PhilosopherCatalog-Background.svg');
  background-size: cover; /* Adjusts the SVG to cover the div */
  background-repeat: no-repeat; /* Ensures the SVG doesn't repeat */

  .Top-carousel{
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    padding-top: 40px;

    .carousel-content {
      width: 100%;
      height: 100%;
      transition: opacity 1s;
    }

    .Theme {
      display: block;
      transition: visibility 0s 1s, opacity 1s cubic-bezier(0.5, 1, 0.5, 1);
    }
    
    .Theme.fade-in {
      opacity: 1;
    }
    
    .Theme.fade-out {
      opacity: 0;
    }
    
    .Theme.hidden {
      height: 0;
      opacity: 0;
      pointer-events: none;
    }

    .Carousel-theme{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      h2{
        font-size: 4vh;
        margin: 3vh 0;
        font-weight: 700;
      }

      div{
        display: flex;
        width: calc(100% - 80px);
        align-items: flex-start;
        justify-content: center;

        .Philosopher{
          display: flex;
          flex-direction: column;
          height: auto;
          align-items: center;
          justify-content: flex-start;

          .Philosopher-intro-image{
            width: calc(60vw * 0.24);
            border: 1px solid var(--Glass-stroke);
            border-radius: 100%;
            box-shadow: 0px 0px 80px 4px #FFF;
            /* filter: drop-shadow(-10px -10px 10px white) drop-shadow(10px -10px 10px white); */
            /* backdrop-filter: blur(2px); */
          }

          .Philosopher-intro-video{
            width: calc(60vw * 0.24);
            border: 1px solid var(--Glass-stroke);
            border-radius: 100%;
            box-shadow: 0px 0px 80px 4px #FFF;
            /* filter: drop-shadow(-10px -10px 10px white) drop-shadow(10px -10px 10px white); */
            /* backdrop-filter: blur(2px); */
          }

          /* h3{
            margin: 0;
          } */
        }
      }

      .Philosophers-in-theme{
        align-items: center;
      }

      p{
        width: 800px;
      }

      button{
        height: 48px;
      }
    }
    
    .Carousel-controls{
      display: flex;
      width: 100%;
      justify-content: center;

      .Dot{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: auto 12px;
        cursor: pointer;
        border: 1.5px solid var(--My-gray);
      }
      
      .Dot.active {
        background-color: var(--My-gray);
        border: 2.5px solid var(--My-gray);
      }

      .Accordion-buttons{
        height: 48px;
        width: 48px;
        background-color: transparent;
        border: transparent;
        transition: background 0.3s ease;
        transition: border 0.3s ease;
        transition: box-shadow 0.3s ease;
        border-radius: 100%;
        padding: 0 ;
        margin: 0 16px;

        img{
          height: 24px;
          width: 15px;
        }
      }

      #Left-accordion-button{
        padding-right: 2px;
      }

      #Right-accordion-button{
        padding-left: 2px;
      }

      .Accordion-buttons:hover{
        background: var(--White-light-BG);
        border: solid 1px white;
        box-shadow: var(--White-light-cast);
      }
    }
  }
}

#Simone-de-Beaviour-gif{
  transform: scaleX(-1);
}

@media (max-width: 1279px) {
  .Philosopher-catalog h3{
    font-size: 2vh;
  }
}

@media (max-width: 919px) {
  .Philosopher-catalog{
    gap: 24px;

    .Top-carousel{
      padding-top: 2vh;
      gap: 24px;

      .Carousel-content{
        .Carousel-theme{
          gap: 16px;
          /* height: 751.50px; */

          h2{
            margin-left: 16px;
            margin-right: 16px;
            font-size: 40px;
          }
    
          .Philosophers-in-theme{
            flex-direction: column;
            justify-content: flex-start;
            width: calc(100% - 80px);
    
            /* Group 1 and 2 */
            div{
              display: flex;
              padding-bottom: 8px;
    
              .Philosopher{
                gap: 0;
        
              }
            }
          }
        }   
      }
    }

    #Theme-description {
      margin: 16px 40px;
      width: auto;
      font-size: 18px;
    }
  }
}

@media (max-width: 743px){
  .Philosopher-catalog{
    .Top-carousel {
      .Carousel-content{
        .Carousel-theme{

          .Philosophers-in-theme {
            margin: 0 24px;
          }
        }   
      }
    }

    .Philosopher{
      max-width: 400px;

      /* .Philosopher-intro-image{
        width: 104px;
        height: auto;
      }

      .Philosopher-intro-video{
        width: 104px;
        height: auto;
      } */
    }

    #Theme-description {
      margin: 16px;
      width: auto;
      font-size: 18px;
    }

  }
}

@media (max-width: 440px){
  .Philosopher-catalog{
    width: 100%;

    .Top-carousel .Carousel-content .Carousel-theme {
      height: 535.95px;

      h2{
        font-size: 3vh;
        margin: 8px 16px;
      }
      
      #Theme-description{
        margin: 0 16px 16px 16px;
        font-size: 2vh;
      }
      
      .Philosophers-in-theme {
        width: 100%;

        div{
          width: calc(100% - 32px);
        }

        h3{
          margin: 8px 0;
        }
      }
    }
  }
}
@media (max-width: 395px){
  .Philosopher-catalog .Top-carousel .Carousel-content .Carousel-theme {
    height: 488px;
  }
}

@media (max-height: 641px){
  .Philosopher-catalog .Top-carousel .Carousel-content .Carousel-theme {
    height: 424px;
  }
}

@media (hover: none) {
  .Philosopher-catalog .Top-carousel .Carousel-controls {
    .Accordion-buttons:hover{
      background: transparent;
      border: none;
      box-shadow: none;
    }

    .Accordion-buttons:active{
      background: var(--White-light-BG);
      border: solid 1px white;
      box-shadow: var(--White-light-cast);
    }
  }
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 1s, opacity 1s cubic-bezier(0.5, 1, 0.5, 1);
  pointer-events: none;
  user-select: none;
}



/* ################################################################################################################### */
/* ###### This is the styling of the affinities/horisontal groups in the bottom part of the Philosopher Catalog ###### */
/* ################################################################################################################### */
.Philosopher-catalog{
  /* overflow-x: hidden; */

  .Affinity-group{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    position: relative;

    h2{
      font-size: 28px;
      margin: 0;
      padding: 0 40px;
      text-align: left;
    }

    .Horisontal-list-of-philosophers{
      display: flex;
      gap: 16px;
      width: calc(100% - 80px);
      overflow-x: scroll;
      padding: 16px 40px 24px 40px;
      scrollbar-width: none;
      -ms-overflow-style: none;

      .Philosopher-card{
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 280px;
        height: 453.04px;
        border: 1px solid var(--Glass-stroke);
        border-radius: 20px;
        background: var(--Glass-gradient);
        box-shadow: var(--Common-shadow);
        overflow: hidden;
        transition: box-shadow 0.3s ease;

        .Image-section{
          display: flex;
          flex-direction: column;
          align-items: center;

          .Backdrop{
            margin-bottom: -80px;
            transition: margin-bottom 0.3s ease;
          }

          .Card-image{
            width: 173.05px;
            border-radius: 100%;
            border: 1px solid #FFF;
            box-shadow: 0px 0px 6.922px 0px rgba(255, 255, 255, 0.25);
            transition: box-shadow 0.3s ease;
            transition: width 0.3s ease;
          }
        }

        .Card-information-container{
          padding: 8px 16px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex-grow: 1;
          text-shadow: 0px 4px 16px rgba(0,0,0,0.25);
          
          h3{
            font-size: 22px;
            margin: 0;
          }
          
          h4{
            color: var(--My-gray);
            font-size: 18px;
            margin: 0;
            height: 46px;
          }
  
          h5{
            color: var(--My-gray);
            font-size: 16px;
            margin: 0;
          }
          
          div{
            display: flex;
            justify-content: center;
            gap: 8px;
            flex-grow: 1;
            
            p{
              font-size: 14px;
              color: var(--My-gray);
              font-weight: 700;
              margin: auto 0;
            }
          }
        }
        
        .Card-buttons{
          display: flex;
          padding: 0 16px 16px 16px;
          gap: 8px;

          button{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 50%;
            font-size: 16px;
            -webkit-text-stroke: none;
            padding: 0;

            img{
              margin: 0 0 1px 0;
            }
          }

          .Primary-outlined-button{
            border: 1px solid var(--Purbleish-blue-uniform);
            color: var(--Purbleish-blue-uniform);
          }

          .Secondary-outlined-button{
            color: var(--My-black);
          }

          .Primary-outlined-button:hover{
            border: 1.5px solid var(--Purbleish-blue-uniform);
            background: linear-gradient(91deg, rgba(5, 112, 234, 0.20) 0%, rgba(150, 32, 251, 0.20) 100%);
          }

          #Remove-philosopher{
            border: 1px solid var(--Destructive-red);
            color: var(--Destructive-red);
          }

          #Remove-philosopher:hover{
            border: 1.5px solid var(--Destructive-red);
          }

        }
      }

      .Philosopher-card:hover{
        background: rgba(255, 255, 255, 0.30);
        box-shadow: 0px 0px 16px -2px #FFF;
        cursor: pointer;

        .Image-section {
          .Backdrop{
            margin-bottom: -88px;
          }

          .Card-image{
            box-shadow: 0px 0px 24px 0px #FFF;
            width: calc(173px * 1.1);
          }
        }
      }
    }

    .Horisontal-list-of-philosophers ::-webkit-scrollbar {
      display: none;
    }

    .Blur-for-horisontal-scroll{
      position: absolute;
      z-index: 1;
      display: flex;
      align-items: center;
      height: calc(100% - 48px);
      width: 120px;
      top: 40px;
      padding: 16px;

      button{
        position: relative;
        background-color: transparent;
        border: transparent;
        transition: background 0.3s ease;
        transition: border 0.3s ease;
        transition: box-shadow 0.3s ease;
        height: 48px;
        width: 48px;
        border-radius: 100%;
        margin-bottom: 32px;
        padding: 0;

        .icon{
          stroke: var(--My-gray);
          transition: stroke 0.3s ease;
        }
      }

      .Scroll-button-left {
        padding-right: 3px;
      }

      .Scroll-button-right{
        padding-left: 3px;
      }
    }

    .Blur-for-horisontal-scroll button:hover{
      background: var(--White-light-BG);
      border: solid 1px white;
      box-shadow: var(--White-light-cast);

      .icon{
        stroke: var(--My-black);
      }
    }

    #Right-blur{
      left: calc(100% - 152px);
      background: radial-gradient(50% 60% at 100% 50%, rgba(224, 225, 241, 0.92) 43.29%, rgba(224, 225, 241, 0.00) 100%);
      justify-content: right;
    }

    #Left-blur{
      background: radial-gradient(50% 60% at 0% 50%, rgba(224, 225, 241, 0.92) 43.29%, rgba(224, 225, 241, 0.00) 100%);
    }
  }
}

@media (max-width: 743px){
  .Philosopher-catalog{
  
    .Affinity-group{
  
      h2{
        font-size: 2.5vh;
        padding: 0 16px;
      }
  
      .Horisontal-list-of-philosophers{
        padding: 16px;
        width: calc(100% - 32px);

        .Philosopher-card{
          width: 184px;
          height: 297px;
  
          .Image-section{
            .Backdrop{
              height: 72px;
              margin-bottom: -56px;
            }
  
            .Card-image{
              width: 106.52px;
            }
          }
          .Card-information-container {
            padding: 8px 0;

            h3{
              font-size: 16px;
              white-space: nowrap;
            }

            h4{
              font-size: 13.6px;
              height: 36px;
              padding: 0 16px;
            }

            h5{
              font-size: 12px;
            }

            div{
              display: none;
            }
          }

          .Card-buttons{
            button{
              font-size: 0;
              padding-left: 8px;
            }
          }
        }
      }
    }

    .Affinity-group .Horisontal-list-of-philosophers .Philosopher-card:hover {
      .Image-section{
        .Backdrop{
          margin-bottom: -56px;
        }
        
        .Card-image{
          width: 106.52px;
        }
      }
    }
  }
}

@media (hover: none) {
  .Philosopher-catalog {
    .Affinity-group .Horisontal-list-of-philosophers .Philosopher-card:hover {
      background: var(--Glass-gradient);
      box-shadow: var(--Common-shadow);
      
      .Image-section{
        .Backdrop{
          margin-bottom: -80px;
        }
        
        .Card-image{
          width: 173.05px;
          box-shadow: none;
        }
      }
      .Card-buttons button:hover{
        background: transparent;

      }
    }

    .Affinity-group .Blur-for-horisontal-scroll{
      display: none;
    }
  }
}

@media (hover: none) and (max-width: 743px) {
  .Philosopher-catalog .Affinity-group .Horisontal-list-of-philosophers .Philosopher-card:hover {
    .Image-section{
      .Backdrop{
        margin-bottom: -56px;
      }
      
      .Card-image{
        width: 106.52px;
        box-shadow: none;
      }
    }
  }
}

/* ######################################################## */
/* ##### This is the styling of the chat group modal ##### */
/* ######################################################## */
.Modal-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 16px;
  left: 88px;
  z-index: 100;
  background: var(--Glass-gradient);
  border-radius: 20px;
  border: 1px solid var(--Glass-stroke);
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  width: 340px;
  max-height: calc(100vh - 32px);

  .Modal-top-bar{
    display: flex;
    justify-content: flex-end;
    width: auto;
    border-bottom: 1px solid var(--Glass-stroke);
    padding: 4px 16px;
  }

  .Header-divider{
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--Glass-stroke);

    h2{
      margin: 0;
      flex-grow: 1;
    }

    button{
      background-color: transparent;
      border: transparent;
      transition: background 0.3s ease;
      transition: border 0.3s ease;
      transition: box-shadow 0.3s ease;
      width: 40px;
      border-radius: 100%;
      padding: 0;
    }
  
    button:hover{
      background: var(--White-light-BG);
      border: solid 1px white;
      box-shadow: var(--White-light-cast);
    }
  }

  .Group-members{
    overflow-y: scroll;

    .Selected-philosopher{
      padding: 0 12px;

      .Content{
        display: flex;
        gap: 8px;
        padding: 8px 4px;
        border-bottom: 1px solid var(--White, #FFF);

        .Philosopher-image {
          display: block;
          width: 40px;
          height: 40px;
          border: 1px solid var(--Glass-stroke);
          border-radius: 100%;
          box-shadow: var(--White-light-cast);
          backdrop-filter: blur(2px);
        }
  
        .Philosopher-name-n-epithet{
          display: flex;
          flex-direction: column;
          align-self: center;
          justify-content: start;
          flex-grow: 1;
          gap: 1px;
  
          h3{
            font-size: 14px;
            margin: 0;
            font-weight: 500;
          }
  
          h4{
            font-size: 12px;
            color: var(--My-gray);
            margin: 0;
            font-weight: 500;
          }
  
        }
      }
    }
    .Selected-philosopher:hover{
      background: var(--White-light-BG);
      cursor: pointer;
    }

    p{
      padding: 16px;
    }

    .Group-of-no-members{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 16px;

      img{
        width: 260px;
      }

      p{
        margin: 16px 0 0 0;
        padding: 0;
        text-align: center;
      }
    }
  }



  .Modal-bottom-bar{
    display: flex;
    padding: 16px;
    position: relative;
    justify-content: center;

    .White-line{
      display: block;
      height: 1px;
      background: var(--Glass-stroke);
      position: absolute;
      width: calc(100% + 1px);
      top: -1px;
      left: -1px;
    }
  }
}

@media (max-width: 743px){
  .Modal-container {
    position: absolute;
    top: 88px;
    left: auto;
    right: 8px;
    max-height: calc(100vh - 96px);
  }
}


/* ######################################################## */
/* ##### This is the styling of the questions section ##### */
/* ######################################################## */

.Questions-section-visible{
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--Section-color);
  border-right: 1px solid var(--Glass-stroke);
  align-items: center;

  h2{
    width: 290px;
    text-align: center;
  }

  p{
    padding: 24px 0;
  }

  .Q-cards-section{
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-top: 1px solid var(--Glass-stroke);
  gap: 8px;
  overflow-y: auto;

    .Info-line {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      gap: 6px;
      padding-bottom: 8px;

      span{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 15px;
        height: 15px;
        background: var(--My-black);
        border-radius: 100%;
        font-size: 14px;
      }

      p{
        margin: 0;
        padding: 0;
        display: flex;
        align-self: center;
      }
    }

    .Clickable-questions{
      display: flex;
      flex-direction: column;
      gap: 8px;


      .Question-card {
        display: block;
        margin: 0 16px;
        width: 240px;
        padding: 16px 8px;
        background-color: rgba(255, 255, 255, 0.30);
        border: 1px solid var(--Glass-stroke);
        border-radius: 8px;
        text-align: center;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease;
        font-family: "Lora", serif;
        animation: questionFadeIn 2s cubic-bezier(0.5, 1, 0.5, 1);
      }

      .Question-card:hover{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.50);
        box-shadow: var(--Common-shadow);
      }

      .Question-card:active{
        background-color: rgba(255, 255, 255, 0.30);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
      }

      .Question-card.disabled{
        background-color: var(--Dissabled-gray);
        border: 1px solid var(--Dissabled-gray);
        pointer-events: none;
      }
    }
  }
}

@keyframes questionFadeIn {
  from {
      opacity: 0; /* Start with opacity 0 */
  }
  to {
      opacity: 1; /* End with opacity 1 */
  }
}

.Questions-section-hidden{
  display: none;
}

/* Dissabling the hover effect thay appears for touch-devices  */
@media (hover: none) {
  .Questions-section-visible .Q-cards-section .Clickable-questions .Question-card:hover {
    box-shadow: none;
  }
}

@media (max-width: 743px){
  .Questions-section-visible{
    border-bottom: 1px solid var(--Glass-stroke);
    border-right: none;
    width: 100%;
    height: fit-content;
    align-items: flex-start;

    h2{
      display: none;
    }

    .Q-cards-section{
      border: none;
      padding: 8px;
      align-items: flex-start;
      width: calc(100% - 16px);

      .Info-line{
        padding: 0 8px;
        position: absolute;
      }

      .Clickable-questions{
        flex-direction: row;
        height: auto;
        width: auto;
        padding: 28px 8px 0 8px;

        .Question-card{
          display: flex;
          width: 260px;
          height: 104px;
          margin: 0;
          padding: 8px;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 380px){
  .Questions-section-visible .Q-cards-section{
    .Info-line{
      padding: 0;
    }

    .Clickable-questions{
      padding: 28px 0px 0 0px;
    }
  }
}

/* ######################################################## */
/* ###### This is the styling of the Pro Tips section ##### */
/* ######################################################## */
.Pro-tips-section-visible{
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--Section-color);
  border-right: 1px solid var(--Glass-stroke);
  align-items: center;

  .Tips{
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--Glass-stroke);
    padding: 0 16px;
    padding-bottom: 16px;
    width: 258px;

    p{
      margin: 16px 0;
      font-size: 18px;
    }

    ul{
      padding-left: 24px;
    }
  }
}

.Pro-tips-section-hidden{
  display: none;
}

@media (max-width: 743px){
  .Pro-tips-section-visible {
    height: auto;
    border-bottom: 1px solid var(--Glass-stroke);
    border-right: none;

    h2{
      display: none;
    }

    .Tips{
      width: auto;
    }
  }
}

/* ######################################################## */
/* ###### This is the styling of the Settings section ##### */
/* ######################################################## */

.Settings-section-visible{
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--Section-color);
  border-right: 1px solid var(--Glass-stroke);
  align-items: center;

  .OpenAI-section{
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: 1px solid var(--Glass-stroke);
    gap: 8px;
    align-items: flex-start;

    p{
      margin: 0;
      font-size: 18px;
    }

    input{
      display: block;
      padding: 0 8px;
      width: 240px;
      height: 40px;
      border-radius: 12px;
      font-size: 16px;
    }

    .OpenAI-APIkey-input-unlocked{
      border: 1px solid var(--Glass-stroke);
      background: var(--Glass-gradient);
    }

    .OpenAI-APIkey-input-locked{
      border: 1px solid var(--Dissabled-gray);
      background: var(--Dissabled-gray);
    }

    .Buttons-container{
      display: flex;
      width: 100%;
      gap: 8px;

      button{
        display: flex;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
        transition: box-shadow 0.3s ease;

      }

      button:hover {
        cursor: pointer;
      }

      button:active {
        cursor: pointer;
      }

      button:focus{
        border: #0459B9;
      }

      .Change-button-inactive{
        background: var(--Dissabled-gray);
        border: 1px solid var(--Dissabled-gray);
        color: var(--My-gray);
        pointer-events: none;
      }

      .Change-button-active{
        background: none;
        border: 2px solid var(--My-black);
        color: var(--My-black);
      }

      .Change-button-active:hover{
        background: var(--Glass-gradient);
        border: 2px solid var(--My-black);
        color: var(--My-black);
        cursor: pointer;
      }

      .Set-button{
        background: var(--New-BluePurble-Gradient);
        color: white;
        border: none;
      }
      .Set-button:hover{
        background: var(--New-BluePurble-Gradient-Hover)
      }
    }
    a{
      padding: 8px 0;
    }
  }
}

.Settings-section-hidden{
  display: none;
}

@media (max-width: 743px){
  .Settings-section-visible{
  height: auto;
  border-bottom: 1px solid var(--Glass-stroke);
  border-right: none;

    h2{
      display: none;
    }

    .OpenAI-section{
    border-top: none;
    padding: 8px;
    width: calc(100% - 16px);

      input{
        width: calc(100% - 18px);
      }

      .Buttons-container button{
        width: 90px;
      }
    }
  }
}

/* ######################################################## */
/* ######### This is the styling of the chat area ######### */
/* ######################################################## */

/* ### Messages ###*/

.Chat-elements{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  align-items: center;
  font-family: "Lora", serif;
  font-size: 20px;
}

@media (max-width: 1041px) {
  .Chat-elements{
    width: calc(100% - 32px);
  }
}

@media (max-width: 743px){
  .Chat-elements{
    width: 100%;
  }
}

@media (max-width: 540px){
  .Chat-elements{
    font-size: 16px;
  }
}

.Overflow{
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: var(--Overflow);
  pointer-events: none;
}

.Thread{
  display: flex;
  padding: 40px calc(50% - 474px) 112px calc(50% - 474px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-grow: 1;
  width: auto;
  overflow-y: scroll;
}

@media (max-width: 540px){
  .Thread{
    gap: 16px;
  }
}

.Message-user-message{
  display: flex;
  padding: 16px 74px 0px 160px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  animation: Bezier-fly-in 0.5s cubic-bezier(0.5, 1, 0.5, 1);
}

@keyframes Bezier-fly-in{
  from{
    opacity: 0;
    transform: translateY(80px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}

.Message-ai-message{
  padding-right: 160px;
  animation: Bezier-fade-message 1s cubic-bezier(0.8, 0, 0.2, 1);
}

@keyframes Bezier-fade-message{
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.Message-author{
  margin: 0 0 6px 0;
  padding: 0px 74px;
  color: var(--My-gray);
  font-weight: 500;
}

.Pictue-n-message{
  display: flex;
  gap: 8px;

  .Philosopher-image{
    display: block;
    width: 64px;
    height: 64px;
    border: 1px solid var(--Glass-stroke);
    border-radius: 100%;
    box-shadow: var(--White-light-cast);
    backdrop-filter: blur(2px);
  }

  .Speech-bubble{
    display: flex;
    padding: 0 8px;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--Glass-stroke);
    background: var(--White-light-BG);
    backdrop-filter: blur(2px);
    box-shadow: var(--White-light-cast);

    p{
      margin:  0.5em 0;
      padding: 0;
      color: #222222;
      font-weight: 500;
    }

    .Message-text{
      margin: 0;
    }
  }
}

.Message-streaming-ai-message{
  padding-right: 160px;
  opacity: 0.50;
}

@media (max-width: 1029px){
  .Philosopher-image{
    margin-left: 16px;
  }

  .Message-user-message{
    padding: 16px 16px 0px 120px;
  }

  .Message-author{
    padding-left: 90px;
  }
  .Message-ai-message{
    padding-right: 80px;
    animation: Bezier-fade-message 1s cubic-bezier(0.8, 0, 0.2, 1);
  }

  .Message-streaming-ai-message{
    padding-right: 80px;
  }
}

@media (max-width: 743px){
  .Message-user-message{
    padding: 16px 16px 0px 80px;
  }

  .Message-ai-message{
    padding-right: 40px;
    animation: Bezier-fade-message 1s cubic-bezier(0.8, 0, 0.2, 1);
  }

  .Message-streaming-ai-message{
    padding-right: 40px;
  }
}

@media (max-width: 540px){
  .Message-author{
    padding-left: 66px;
  }

  .Pictue-n-message {
    .Speech-bubble{
      padding: 0 8px;
    }

    .Philosopher-image{
    width: 40px;
    height: 40px;
    }
  }


}

@media (max-width: 380px){
.Philosopher-image{
  margin-left: 8px;
}

.Message-author{
  padding-left: 40px;
  margin-bottom: 2px;
}

.Message-user-message{
  padding: 16px 8px 0px 80px;
}

  .Pictue-n-message {
  gap: 4px;

    .Speech-bubble{
      padding: 6px;
    }
    .Philosopher-image{
      width: 26px;
      height: 26px;
    }
  }
}

.fade-in-token{
  color: #222222;
  font-size: 1em;
  font-weight: 500;
  animation: Fade-in-token 2s ease-out;
  display: inline-block; /* Needed to apply animation to inline elements like span */
}

@keyframes Fade-in-token{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ### Blur screen stars and Diffused lights ### */
.Blur-screen{
  display: block;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  gap: 100px;
  z-index: -2;
  background: rgba(224, 225, 241, 0.50);
  backdrop-filter: blur(40px);
}

.Diffused-lights-container{
  position: absolute;
  display: block;
  z-index: -3;
  height: 100vh;
  width: 100vw;
  will-change: transform;
  
  /* Lets try and make the combined lights inserted on first render and upon adding new lights something devideable with 5 viewpoint heights */
  div{
    display: block;
    margin: 0;
    height: 33.3vh;
    width: 100vw;
    background-color: green;
  }

  .Light-1{
    background: linear-gradient(90deg, #CDE7F9 0%, #D6D3F8 50.28%, #E4DDEC 100%);
  }
  .Light-2{
    background: linear-gradient(90deg, #D2DBF4 0%, #E1D4EF 50.28%, #EEDCEB 100%);
  }
  .Light-3{
    border-radius: 20%;
    margin-left: 33vw;
    width: 66vw;
    background: #DBD1FF;
  }
  .Light-4{
    background: linear-gradient(90deg, rgba(248, 228, 251, 0.80) 0%, rgba(249, 232, 253, 0.80) 50.5%, rgba(225, 227, 250, 0.80) 100%);  }
  .Light-5{
    margin-left: 33vw;
    border-radius: 50%;
    background: linear-gradient(90deg, #BCB2CC 0%, #B8C7E0 50.5%, #E4D5EE 100%);
  }
  .Light-6{
    background: linear-gradient(90deg, #EBE3FE 0%, #DDE1FF 50.5%, #C9D3FF 100%);
  }
  .Light-7{
    border-radius: 40%;
    background: linear-gradient(90deg, #B6CAFB 0%, #C2CEFB 50.5%, #CBCFFB 100%);
  }
  .Light-8{
    background: linear-gradient(90deg, #E7F4FF 0%, #B3D2F4 50.5%, #9EB2D6 100%);
  }
  .Light-9{
    background: linear-gradient(90deg, #EDE3EA 0%, #CADBEE 50.5%, #A5B4D1 100%);
  }
}

blockquote {
  background-color:var(--White-light-BG);
  border-left: 0.25em solid var(--Glass-stroke);
  margin: 0.5em 0;
  padding: 0.5em 1em;
  font-style: italic;
  border-radius: 6px;
}

code {
  background-color: var(--White-light-BG);
  padding: 0.1em 0.2em;
}

hr {
  height: 1px;
  background-color: var(--Glass-stroke);
  box-shadow: var(--White-light-cast);
  border: none;
  width: 99.5%;
}

h1{
  width: 100%;
}



/* ######################################################## */
/* ######## This is the styling of the prompt area ######## */
/* ######################################################## */

.Input-n-notification{
  position: absolute;
  z-index: 2;
  display: flex;
  padding: 0 0 8px 0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  bottom: 0;
  width: 100%;

  .Input-container{
    display: flex;
    width: 798px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    border: 1px solid var(--Glass-stroke);
    border-radius: 16px;
    background: var(--Glass-gradient);
    box-shadow: 0px 6px 16px -1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 912px) {
    .Input-container{
      width: calc(100% - 32px);
    }
  }

  @media (max-width: 540px){
    .Input-container{
      align-items: flex-start;
    }
  }

  @media (max-width: 380px){
    .Input-container{
      width: calc(100% - 16px);
    }
  }

  textarea{
    flex: 1;
    background-color: transparent;
    border: 0;
    width: 100%;
    min-height: 44;
    max-height: 200px;
    font-family: inherit;
    resize: none;
    margin-left: 8px;
    /* Adjust padding the top and bottom padding accordingly */
    padding: 8px 0 9px 0;
    box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
    overflow: auto; /* Allow scrollbar if content exceeds max-height */
    font-size: 1em;
  }
  
  @media (max-width: 540px){
    textarea{
      height: 52px;
      padding: 5px 0 6px 0;
    }
  }

  textarea:focus {
    outline: #0459B9;
  }

  .Button-wrapper{
    display: flex;
    align-self: stretch;
    align-items: flex-end;

    button{
      margin: 6px 6px 6.5px 0;
      display: flex;
      background: var(--New-BluePurble-Gradient);
      border: var(--New-BluePurble-Glow-edge);
      font-size: 16px;
      font-weight: 700;
      color: white;
      width: 90px;
      height: 40px;
      padding: 0 5px 0 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      box-shadow: var(--New-BluePurble-Glow);
      transition: box-shadow 0.3s ease;
    }

    .Submit-button:hover {
      background: var(--New-BluePurble-Gradient-Hover);
      box-shadow: 0px 0px 4px 0px #4B4BF3;
      cursor: pointer;
    }

    .Submit-button:active {
      box-shadow: 0px 0px 12px 0px #4B4BF3, 0px 0px 8px 0px #FFF inset;
      cursor: pointer;
    }

    .Submit-button:focus{
      border: #0459B9;
    }

    .Loader-button{
      background: var(--Dissabled-gray);
      border: none;
      box-shadow: none;
      padding: 3px 0 0 0;
    }
  }


  p {
    margin: 0;
    padding: 0;
    color: var(--My-gray);
    font-size: 12px;
    font-family:Arial;

    @media (max-width: 612px){
      span {
        display: none;
      }
    }
  }
}

/* ########################################################### */
/* ###### This is the styling of the Confirmation Modal ###### */
/* ########################################################### */

#Modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height:100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Attention-modal-overlay-background);
  z-index: 100;
  pointer-events: auto;
  cursor: default;

  .Modal-content{
    width: 328px;
    align-items: center;

    h2{
      margin: 16px;
      font-size: 28px;
    }

    p{
      width: 100%;
    }

    div{
      width: 100%;

      button{
        height: 40px;
      }
    }
  }
}

.Modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height:100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Attention-modal-overlay-background);
  z-index: 100;
  pointer-events: auto;
  cursor: default;

  .Modal-content{
    display: flex;
    flex-direction: column;
    background: var(--Attention-modal-background);
    border: 1px solid var(--Glass-stroke);
    border-radius: 20px;
    width: 328px;
    align-items: center;
    padding: 0 16px;

    h2{
      margin: 16px;
      font-size: 28px;
      font-family: lora, serif;
    }

    p{
      display: flex;
      width: 100%;
      text-align: left;
      margin-top: 0;
    }

    .Decision-description{
      font-family: "Lora", serif;
      font-size: 18px;
    }

    div{
      display: flex;
      gap: 8px;
      width: 100%;
      padding: 16px;

      button{
        width: 50%;
      }
    }
  }
}

/* ######################################################## */
/* ##### This is the styling of the Philosopher Pages ##### */
/* ######################################################## */

#Overlay-for-the-philosopher-page{
  align-items: start;
  padding: 40px 0 40px 0;
  font-family: "Lora", serif;
  -webkit-text-stroke: 0.3px var(--Glass-stroke); 
  overflow: scroll;

  .Modal-content{
    width: 720px;
    margin: 0 16px 80px 16px;
    padding: 0 0 40px 0;
    overflow: hidden;
    position: relative;
    gap: 24px;

    .Images-n-quote-section{
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 0;

      .Backdrop-n-qoute{
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 0;

        .Backdrop{
          height: 184px;
          margin-bottom: -184px;
          width: 100%;
        }
        p{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 184px;
          width: 424px;
          margin: 0 0 0 260px;
          font-size: 24px;
          text-align: center;
          font-style: italic;
          text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
          margin-bottom: -144px;
        }
      }

      .Profile-picture{
        width: 200px;
        border: 1px solid var(--Glass-stroke);
        border-radius: 100%;
        box-shadow: 0px 0px 16px -2px #FFF;
        margin-left: 40px;
      }

      .X-button{
        position: absolute;
        width: 40px;
        top: 12px;
        left: 12px;
      }
    }

    .Top-information{
      display: flex;
      padding: 0 40px;
      width: calc(100% - 80px);
      gap: 24px;

      div{
        padding: 0;
        width: 50%;
      }

      .Card-information-n-country{
        display: flex;
        flex-direction: column;

        h2{
          margin: 0;
          font-weight: 500;
          font-size: 26px;
        }

        .Info-container{
          display: flex;
          flex-direction: column;
          color: var(--My-gray);
          width: auto;
          gap: 16px;

          h3{
            margin: 0;
            font-weight: 500;
            font-size: 18px;
          }

          .Lifespan-n-nationality{
            width: auto;

            h4{
              margin: 0;
              font-weight: 500;
            }
          }

          .Tags{
            display: flex;
            width: auto;
            

            p{
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              width: auto;
            }
          }

          .Buttons{
            width: 100%;

            button{
              width: auto;
            }

            .Secondary-filled-button{
              border: 1px solid #FFF;
              background: rgba(255, 255, 255, 0.80);
              box-shadow: 0px 0px 4px 0px #FFF inset, 0px 0px 24px 0px #FFF;
              transition: box-shadow 0.3s ease;
            }

            .Secondary-filled-button:hover{
              border: 1px solid rgba(255, 255, 255, 0.30);
              background: rgba(255, 255, 255, 0.50);
              box-shadow: 0px 0px 4px 0px #FFF inset, 0px 0px 8px 0px #FFF;
            }

            .Secondary-filled-button:active{
              border: 1px solid var(--White, #FFF);
              background: rgba(255, 255, 255, 0.50);
              box-shadow: 0px 0px 4px 0px #FFF inset, 0px 0px 32px 0px #FFF;
            }
          }
        }
      }

      .Life-accomplishments{
        display: flex;
        flex-direction: column;
        gap: 0;

        p{
          margin: 8px 0 0 0;
        }

        ol{
          margin: 6px 0;
          padding-left: 20px;
        }
      }
    }
  }

  @media (max-width: 743px){
    .Modal-content{ 
      .Top-information{
        flex-direction: column;
      }
      .Images-n-quote-section{
        .Backdrop-n-qoute{ 
          p{
            margin: 0 40px 32px 40px ;
            width: auto;
            height: 216px;
          }

          .Backdrop{
            margin-bottom: -352px;
            height: 400px;
          }
        }

        .Profile-picture{
          margin: 0 auto 0 auto;
        }
      }

      .Top-information{
        padding: 0 16px;
        width: auto;

        div{
          width: auto;
        }

        .Card-information-n-country{
          align-items: center;

          .Info-container{
            align-items: center;
          }

          .Buttons{
            display: none;
          }
        }
      }
    }
  }
}